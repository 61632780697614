<template>
  <app-module-view>
    <template slot="body">
      <div>
        <div class="m-b-10">
          <div class="row">
            <div class="col-lg-12">
              <div class="text-right m-r-5">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="save"
                >
                  <i class="fa fa-save "></i> {{ $t('beUser.save') }}
                </button>
                <app-button-delete v-if="beUser.id" @deleteRecord="deleteRecord"></app-button-delete>
                <app-button-close route-name="beUser_list"></app-button-close>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="card">
              <div class="card-header">{{ $t('beUser.details') }}</div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-4">
                    <app-input
                      v-model="beUser.username"
                      @blur="$v.beUser.username.$touch()"
                      :error="$v.beUser.username.$error"
                      id="beUser_username"
                      :label="$t('beUser.username')"
                    >
                    </app-input>
                  </div>
                  <div class="col-lg-8">
                    <app-input
                      v-model="beUser.email"
                      @blur="$v.beUser.email.$touch()"
                      :error="$v.beUser.email.$error"
                      id="beUser_email"
                      :label="$t('beUser.email')"
                    >
                    </app-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <app-input
                      v-model="beUser.firstName"
                      id="beUser_firstName"
                      :label="$t('beUser.firstName')"
                    >
                    </app-input>
                  </div>
                  <div class="col-lg-6">
                    <app-input
                      v-model="beUser.lastName"
                      id="beUser_lastName"
                      :label="$t('beUser.lastName')"
                    >
                    </app-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card">
              <div class="card-header">{{ $t('beUser.settings') }}</div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6">
                    <app-select
                      v-model="beUser.defaultSite"
                      :options="allSites()"
                      id="beUser_defaultSite"
                      :label="$t('beUser.defaultSite')"
                      @change="loadSiteDefiningItems(true)"
                    >
                    </app-select>
                  </div>
                  <div class="col-lg-6">
                    <label for="beUser_defaultRubric">{{ $t('beUser.defaultRubric') }}</label>
                    <div class="form-group">
                      <select
                        v-model="beUser.defaultRubric"
                        id="beUser_defaultRubric"
                        class="form-control"
                      >
                        <option
                          v-for="(rubric, index) in rubricsBySite"
                          :key="`item-${index}`"
                          :value="rubric.id"
                          :selected="rubric.id == beUser.defaultRubric"
                        >
                          <template v-if="rubric.lvl === 1">&nbsp;&nbsp;&nbsp;</template>
                          <template v-if="rubric.lvl === 2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</template>
                          {{ rubric.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <app-select
                      v-model="beUser.department"
                      :options="departmentsBySite"
                      optionTitle="name"
                      id="beUser_department"
                      :label="$t('beUser.department')"
                    >
                    </app-select>
                  </div>
                </div>

                <div class="row" v-if="beUser.id">
                  <div class="col-lg-12">
                    <app-button-copy-to-clipboard
                      v-if="beUser.confirmationToken"
                      data-test="beUser_btn_copy_to_clipboard"
                      :label="$t('beUser.copyResetLink')"
                      :data="resetLink"
                      :notify-message="$t('beUser.message.resetLinkCopied')"
                    >
                    </app-button-copy-to-clipboard>
                    <button
                      type="button"
                      class="btn btn-red"
                      @click="resetPassword()"
                    >
                      {{ $t('beUser.resetPassword') }}
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card">
              <div class="card-header">{{ $t('beUser.authorization') }}</div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6">
                    <app-checkbox
                      v-model="beUser.enabled"
                      id="beUser_enabled"
                      :label="$t('beUser.enabled')"
                    >
                    </app-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <label>{{ $t('beUser.roles') }}</label>
                    <app-multi-select
                      v-model="userRoles"
                      :options="availableRoles"
                      label="title"
                      track-by="id"
                      id="beUser_roles"
                    >
                    </app-multi-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <label>{{ $t('beUser.userSites') }}</label>
                    <app-multi-select
                      v-model="userSites"
                      :options="allSites()"
                      :limit="50"
                      label="title"
                      track-by="id"
                      id="beUser_sites"
                    >
                    </app-multi-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import BeUserModel from '../../model/beUser/BeUser'
import Input from '../../components/form/inputs/Input'
import Select from '../../components/form/select/Select'
import Checkbox from '../../components/form/Checkbox'
import ButtonDelete from '../../components/shared/ButtonDelete'
import ButtonClose from '../../components/shared/ButtonClose'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import NotifyService from '../../services/NotifyService'
import { AVAILABLE_VALUES as AVAILABLE_ROLES } from '../../model/ValueObject/UserRole'
import MultiSelect from '../../components/form/select/MultiSelect'
import BtnCopyToClipboard from '../../components/buttons/BtnCopyToClipboard'
import appConfig from '../../config'

export default {
  name: 'BeUserNew',
  data () {
    return {
      beUser: this._.cloneDeep(BeUserModel),
      rubricsBySite: [],
      departmentsBySite: [],
      availableRoles: AVAILABLE_ROLES,
      userRoles: [],
      userSites: []
    }
  },
  validations: {
    beUser: {
      username: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(180)
      },
      email: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(180)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appSelect: Select,
    appCheckbox: Checkbox,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appMultiSelect: MultiSelect,
    appButtonCopyToClipboard: BtnCopyToClipboard
  },
  computed: {
    resetLink () {
      return `${appConfig.core.passwordResetUrl()}/reset/${this.beUser.confirmationToken}`
    }
  },
  watch: {
    userRoles () {
      this.beUser.roles = this.userRoles.map(function (userRole) {
        return userRole.id
      })
    },
    userSites () {
      this.beUser.sites = this.userSites.map(function (userSite) {
        return userSite.id
      })
    }
  },
  methods: {
    allSites () {
      return this.$store.getters['site/all']
    },
    loadSiteDefiningItems (resetItems) {
      if (resetItems) {
        this.beUser.defaultRubric = null
        this.beUser.department = null
      }
      this.loadRubricsBySite()
      this.loadDepartmentsBySite()
    },
    async loadRubricsBySite () {
      await this.$store.dispatch('rubric/fetchRubricsBySiteId', this.beUser.defaultSite)
      this.rubricsBySite = this.$store.state.rubric.all.filter(item => item.site === this.beUser.defaultSite)
    },
    loadDepartmentsBySite () {
      this.departmentsBySite = this.$store.getters['department/departmentsBySite'](this.beUser.defaultSite)
    },
    async save () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('beUser.error.required_fields'))
        return
      }

      // Create user
      this.$store.dispatch('beUser/create', this.beUser)
        .then(() => {
          if (this.$store.getters['beUser/error'] === null) {
            this.beUser = this.$store.getters['beUser/detail']
            NotifyService.setSuccessMessage(this.$t('beUser.message.updated_record'))
            this.$router.push('/beUser/' + this.beUser.id + '/edit')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['beUser/error'])
          }
        })
        .catch(error => console.log(error))
    },
    deleteRecord () {
      this.$store.dispatch('beUser/deleteRecord', this.beUser)
        .then(() => {
          if (this.$store.getters['beUser/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('beUser.message.deleted_record'))
            this.$router.push('/beUser')
          } else {
            NotifyService.setErrorMessage(this.$t('beUser.error.' + this.$store.getters['beUser/error']))
          }
        })
        .catch(error => console.log(error))
    }
  }
}
</script>
